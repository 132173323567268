<template>
  <v-card class="rounded-lg shadow-regular">
    <v-card-text class="py-5 px-7">
      <p class="card-title mb-0 black--text">Dialogs</p>
      <v-container>
        <v-row justify="space-between" class="my-2">
          <!-- Regular Dialog Section -->
          <v-col cols="5" class="mr-3 container-style">
            <p class="section-title mb-0 text-center">Regular Dialog</p>
            <v-container>
              <!-- Default Regular Dialog -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Default</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Regular Dialog')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col class="d-flex justify-center">
                  <!-- Dialog Activator -->
                  <v-btn
                    class="main-action-btn"
                    @click="displayRegularDialog()"
                  >
                    Display
                  </v-btn>
                  <!-- Dialog Activator -->
                </v-col>
              </v-row>

              <!-- Link Dialog -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Link</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Link Dialog')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col class="d-flex justify-center">
                  <!-- Dialog Activator -->
                  <v-btn class="main-action-btn" @click="displayLinkDialog()">
                    Display
                  </v-btn>
                  <!-- Dialog Activator -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <!-- Confirmation Dialog Section -->
          <v-col cols="6" class="mr-3 container-style">
            <p class="section-title mb-0 text-center">Confirmation Dialog</p>
            <v-container>
              <!-- Default Confirmation Dialog -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Default</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Confirmation Dialog')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col class="d-flex justify-center">
                  <!-- Dialog Activator -->
                  <v-btn
                    class="main-action-btn"
                    @click="displayConfirmationDialog()"
                  >
                    Display
                  </v-btn>
                  <!-- Dialog Activator -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DialogWiki',

  methods: {
    displayRegularDialog() {
      const params = {
        type: 'regular',
        title: 'Regular modal title',
        message:
          'This is the body of the regular modal, explaining to the user what will happen if the action is taken.',
        reinforcement: null,
        actionBtnText: 'Ok',
        actionBtn: this.proceedWithRegularAction,
      };

      this.$store.dispatch('popupDialog/configureDialog', params);
      this.$store.dispatch('popupDialog/toggleDialogDisplay', true);
    },

    displayLinkDialog() {
      const params = {
        type: 'regular',
        title: 'Link URL:',
        message: null,
        reinforcement: null,
        link: 'https://google.com',
        actionBtnText: 'Ok',
        actionBtn: this.proceedWithLinkAction,
      };

      this.$store.dispatch('popupDialog/configureDialog', params);
      this.$store.dispatch('popupDialog/toggleDialogDisplay', true);
    },

    displayConfirmationDialog() {
      const params = {
        type: 'confirmation',
        title: 'Confirmation modal title',
        message:
          'This is the body of the confirmation modal, explaining to the user what will happen if the action is taken.',
        reinforcement: 'This is the confirmation question?',
        actionBtnText: 'Proceed',
        actionBtn: this.proceedWithConfirmationAction,
      };

      this.$store.dispatch('popupDialog/configureDialog', params);
      this.$store.dispatch('popupDialog/toggleDialogDisplay', true);
    },

    proceedWithRegularAction() {
      console.log('Great Success! Regular action registered');
    },

    proceedWithLinkAction() {
      console.log('Great Success! Link action registered');
    },

    proceedWithConfirmationAction() {
      console.log('Great Success! Thank you for the confirmation');
    },

    triggerToast(name) {
      const toastParams = {
        position: { top: true, right: true },
        timeout: 3000,
        messages: { success: `${name} copied to clipboard` },
        type: 'success',
      };

      this.$store.dispatch(
        'toastNotification/setupToastNotification',
        toastParams
      );
      this.$store.dispatch('toastNotification/showToastNotification', true);
    },

    selectedComponentHandler(name) {
      let selectedComponent = null;

      switch (name) {
        case 'Regular Dialog':
          selectedComponent = `displayRegularDialog() {
          const params = {
            type: 'regular',
            title: 'Regular modal title',
            message:
              'This is the body of the regular modal, explaining to the user what will happen if the action is taken.',
            reinforcement: null,
            actionBtnText: 'Ok',
            actionBtn: this.proceedWithRegularAction,
          };

          this.$store.dispatch('popupDialog/configureDialog', params);
          this.$store.dispatch('popupDialog/toggleDialogDisplay', true);
        },`;
          break;
        case 'Link Dialog':
          selectedComponent = `displayLinkDialog() {
          const params = {
            type: 'regular',
            title: 'Link URL:',
            message: null,
            reinforcement: null,
            link: 'https://google.com',
            actionBtnText: 'Ok',
            actionBtn: this.proceedWithLinkAction,
          };

          this.$store.dispatch('popupDialog/configureDialog', params);
          this.$store.dispatch('popupDialog/toggleDialogDisplay', true);
        },`;
          break;
        case 'Confirmation Dialog':
          selectedComponent = `displayConfirmationDialog() {
          const params = {
            type: 'confirmation',
            title: 'Confirmation modal title',
            message:
              'This is the body of the confirmation modal, explaining to the user what will happen if the action is taken.',
            reinforcement: 'This is the confirmation question?',
            actionBtnText: 'Proceed',
            actionBtn: this.proceedWithConfirmationAction,
          };

          this.$store.dispatch('popupDialog/configureDialog', params);
          this.$store.dispatch('popupDialog/toggleDialogDisplay', true);
        },`;
          break;
      }
      return selectedComponent;
    },

    copyToClipboard(name) {
      let component = this.selectedComponentHandler(name);
      navigator.clipboard.writeText(component);
      this.triggerToast(name);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-style {
  border: 1px dashed $grey-regular !important;
  border-radius: 5px;
}

.style-label {
  font-size: 13px !important;
}
</style>
